@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-Book.otf);
}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-Light.otf);
  font-weight: 200;

}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-LightItalic.otf);
  font-weight: 200;
  font-style: italic
}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-BoldItalic.otf);
  font-weight: 600;
  font-style: italic
}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-Black.otf);
  font-weight: 800;
}

@font-face {
  font-family: "Verlag";
  src: local(Verlag), url(assets/fonts/verlag/Verlag-BlackItalic.otf);
  font-weight: 800;
  font-style: italic
}